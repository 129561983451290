import { Accordion, Link } from '@theme/components';
import { FooterMenuType } from '@theme/widgets/footer-menu';
import { ReactElement } from 'react';

type FooterMenuContentType = {
  data: Pick<FooterMenuType['data'], 'attributes'>;
  contactComponent?: ReactElement;
  socialComponent?: ReactElement;
};

export const FooterMenuContent = ({
  data,
  contactComponent,
  socialComponent
}: FooterMenuContentType) => {
  return (
    <div className="flex-1">
      <div className="hidden justify-between text-xs md:flex md:py-4 md:gap-8 md:mt-7 lg:container lg:px-0">
        <div className="md:border-t border-gray md:w-1/4">
          <div
            className="text-xs mb-[30px] leading-3 text-black-400 font-medium md:mt-7"
            data-testid="footer-categories"
          >
            {data?.attributes?.first_column_title?.value}
          </div>

          <div className="flex">
            <ul className="mr-10 lg:mr-8 xl:mr-16 rtl:mr-0 leading-9">
              {data?.attributes?.first_column_items
                ?.filter(
                  (category) => category?.value?.is_side_column_item === 'false'
                )
                .map((item, i) => (
                  <li className="" key={i}>
                    <Link
                      href={item?.value?.redirect_url}
                      target={
                        item?.value?.is_target_blank === 'true'
                          ? '_blank'
                          : '_self'
                      }
                      data-testid={`footer-categories-${item?.value?.name
                        ?.toLocaleLowerCase()
                        .split(' ')
                        .join('')}`}
                      className="leading-3 text-black"
                    >
                      {item?.value?.name}
                    </Link>
                  </li>
                ))}
            </ul>

            <ul className="lg:mr-8">
              {data?.attributes?.first_column_items
                ?.filter(
                  (category) => category?.value?.is_side_column_item === 'true'
                )
                .map((item, i) => (
                  <li className="" key={i}>
                    <Link
                      href={item?.value?.redirect_url}
                      target={
                        item?.value?.is_target_blank === 'true'
                          ? '_blank'
                          : '_self'
                      }
                      data-testid={`footer-categories-${item?.value?.name?.toLocaleLowerCase()}`}
                      className="leading-3 "
                    >
                      {item?.value?.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className="md:border-t border-gray md:w-1/4">
          <div
            className="text-xs mb-[30px] leading-3 text-black-400 font-medium md:mt-7"
            data-testid="footer-my-account"
          >
            {data?.attributes?.second_column_title?.value}
          </div>
          <div>
            <ul className="mr-8 rtl:mr-0 leading-9">
              {data?.attributes?.second_column_items?.map((item, i) => (
                <li className="" key={i}>
                  <Link
                    href={item?.value?.redirect_url}
                    target={
                      item?.value?.is_target_blank === 'true'
                        ? '_blank'
                        : '_self'
                    }
                    className="leading-3  text-black"
                  >
                    {item?.value?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="md:border-t border-gray md:w-1/4">
          <div
            className="mb-[30px] leading-3 font-medium md:mt-7"
            data-testid="footer-customer-care"
          >
            {data?.attributes?.third_column_title?.value}
          </div>

          <div>
            <ul className="lg:mr-8 xl:mr-16 rtl:mr-0 leading-9">
              {data?.attributes?.third_column_items?.map((item, i) => (
                <li key={i}>
                  <Link
                    href={item?.value?.redirect_url}
                    target={
                      item?.value?.is_target_blank === 'true'
                        ? '_blank'
                        : '_self'
                    }
                    className="leading-3 text-black"
                  >
                    {item?.value?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="md:border-t border-gray md:w-1/4">
          <div
            className="mb-[30px] leading-3 text-black-400 font-medium md:mt-7"
            data-testid="footer-categories"
          >
            {data?.attributes?.contact_column_title?.value}
          </div>

          {contactComponent}

          {socialComponent}
        </div>
      </div>

      <div className="block md:hidden border-t border-gray mt-5">
        <Accordion
          title={data?.attributes?.first_column_title?.value}
          titleClassName="text-xs text-black-400 font-medium"
          className="pt-[23px] pb-[21px] mb-[23px]"
          iconSize={8}
        >
          <div>
            <ul className="text-xs px-4">
              {data?.attributes?.first_column_items
                ?.filter(
                  (category) => category?.value?.is_side_column_item === 'false'
                )
                .map((item, i) => (
                  <li className="mb-2" key={i}>
                    <Link
                      href={item?.value?.redirect_url}
                      target={
                        item?.value?.is_target_blank === 'true'
                          ? '_blank'
                          : '_self'
                      }
                    >
                      {item?.value?.name}
                    </Link>
                  </li>
                ))}
            </ul>

            <ul className="text-xs px-4">
              {data?.attributes?.first_column_items
                ?.filter(
                  (category) => category?.value?.is_side_column_item === 'true'
                )
                .map((item, i) => (
                  <li className="mb-2" key={i}>
                    <Link
                      href={item?.value?.redirect_url}
                      target={
                        item?.value?.is_target_blank === 'true'
                          ? '_blank'
                          : '_self'
                      }
                    >
                      {item?.value?.name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </Accordion>
        <Accordion
          title={data?.attributes?.second_column_title?.value}
          titleClassName="text-xs font-medium"
          className="pb-[21px] mb-[23px]"
          iconSize={8}
        >
          <div>
            <ul className="text-xs px-4">
              {data?.attributes?.second_column_items?.map((item, i) => (
                <li className="mb-2" key={i}>
                  <Link
                    href={item?.value?.redirect_url}
                    target={
                      item?.value?.is_target_blank === 'true'
                        ? '_blank'
                        : '_self'
                    }
                  >
                    {item?.value?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Accordion>
        <Accordion
          title={data?.attributes?.third_column_title?.value}
          titleClassName="text-xs font-medium"
          className="pb-[21px] mb-[23px]"
          iconSize={8}
        >
          <div>
            <ul className="text-xs px-4">
              {data?.attributes?.third_column_items?.map((item, i) => (
                <li className="mb-2" key={i}>
                  <Link
                    href={item.value.redirect_url}
                    target={
                      item.value.is_target_blank === 'true' ? '_blank' : '_self'
                    }
                  >
                    {item.value.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </Accordion>
        <Accordion
          className="pb-[21px] mb-0"
          title={'common.footer.contact'}
          isTitleTrans={true}
          titleClassName="text-xs font-medium"
          iconSize={8}
        >
          <div className="px-4">{contactComponent}</div>
        </Accordion>

        {socialComponent}
      </div>
    </div>
  );
};
