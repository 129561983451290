'use client';

import { useLocalization } from '@akinon/next/hooks';

export const ErrorCode = ({ code }) => {
  const { t } = useLocalization();

  return (
    <div className="py-0 lg:py-10 font-serif">
      <div className="flex justify-center">
        <div className="p-3 flex items-center">
          <h1 className="font-bold text-2xl">
            {t(`common.page_${code}.title`)}
          </h1>
        </div>
        <div className="border-r mx-3"></div>
        <div className="p-3 flex items-center">
          <p>{t(`common.page_${code}.description`)}</p>
        </div>
      </div>
    </div>
  );
};
