import { Link, Trans } from '@theme/components';

export const FooterContactContent = ({ data }) => {
  const hasData =
    data?.attributes?.email?.value ||
    data?.attributes?.phone?.value ||
    data?.attributes?.button_url?.value;

  const hasButton =
    data?.attributes?.button_url?.value && data?.attributes?.button_text?.value;

  return (
    hasData && (
      <div className="flex flex-col w-full gap-y-[30px] md:mt-7 md:mb-9">
        {data?.attributes?.email?.value && (
          <div>
            <div className="text-xs leading-5 text-black-400">
              <Trans i18nKey="common.footer.email" />
            </div>
            <a
              href={`mailto:${data?.attributes?.email?.value || '#'}`}
              className="text-xs leading-5 text-black"
            >
              {data?.attributes?.email?.value}
            </a>
          </div>
        )}
        {data?.attributes?.phone?.value && (
          <div>
            <div className="text-xs leading-5 text-black-400">
              <Trans i18nKey="common.footer.phone" />
            </div>
            <div className="text-xs leading-5 text-black">
              {data?.attributes?.phone?.value}
            </div>
          </div>
        )}
        {hasButton && (
          <div className="text-black text-xs leading-5">
            <Link
              href={data?.attributes?.button_url?.value}
              className="underline"
            >
              {data?.attributes?.button_text?.value}
            </Link>
          </div>
        )}
      </div>
    )
  );
};
