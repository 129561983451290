'use client';

import { Image } from '@akinon/next/components/image';
import { useLocalization } from '@akinon/next/hooks';
import { MenuItemType } from '@akinon/next/types';
import { menuGenerator } from '@akinon/next/utils';
import BandSlider, { BandSliderType } from '@theme/components/band-slider';
import { ErrorCode } from '@theme/components/error-code';
import HeaderLogo from '@theme/components/header-logo';
import { FooterBannerContent } from '@theme/views/footer-banner-content';
import { FooterContactContent } from '@theme/views/footer-contact-content';
import { FooterMenuContent } from '@theme/views/footer-menu-content';
import { FooterSocialContent } from '@theme/views/footer-social-content';
import MobileHamburgerButton from '@theme/views/header/mobile-hamburger-button';
import MobileMenu from '@theme/views/header/mobile-menu';
import MobileSearchButton from '@theme/views/header/mobile-search-button';
import Navbar from '@theme/views/header/navbar';
import { FooterBannerType } from '@theme/widgets/footer-banner';
import { FooterContactType } from '@theme/widgets/footer-contact';
import { FooterMenuType } from '@theme/widgets/footer-menu';
import { FooterSocialType } from '@theme/widgets/footer-social';
import clsx from 'clsx';
import { Link } from 'components';
import { useEffect, useState } from 'react';
import { ROUTES } from 'routes';

const fetchMenuData = (setData) => {
  fetch(`/api/client/menus/generate/?depth_height=3`)
    .then((resp) => resp.json())
    .then((resp) => {
      setData(menuGenerator(resp.menu));
    });
};

const fetchBandData = (setData) => {
  fetch('/widgets/header-band-slider/?format=json')
    .then((resp) => resp.json())
    .then((resp) => {
      setData(resp);
    });
};

const fetchFooterBannerData = (setData) => {
  fetch('/widgets/footer-banner/?format=json')
    .then((resp) => resp.json())
    .then((resp) => {
      setData(resp);
    });
};

const fetchFooterMenuData = (setData) => {
  fetch('/widgets/footer-menu-fe/?format=json')
    .then((resp) => resp.json())
    .then((resp) => {
      setData(resp);
    });
};

const fetchContactData = (setData) => {
  fetch('/widgets/footer-contact-fe/?format=json')
    .then((resp) => resp.json())
    .then((resp) => {
      setData(resp);
    });
};

const fetchSocialData = (setData) => {
  fetch('/widgets/footer-social-fe/?format=json')
    .then((resp) => resp.json())
    .then((resp) => {
      setData(resp);
    });
};

export default function Error() {
  const { t } = useLocalization();

  // Header
  const [bandData, setBandData] =
    useState<Pick<BandSliderType['data'], 'attributes'>>();
  const [menuData, setMenuData] = useState<MenuItemType[]>([]);

  // Footer
  const [footerBannerData, setFooterBannerData] = useState<FooterBannerType>();
  const [footerMenuData, setFooterMenuData] =
    useState<Pick<FooterMenuType['data'], 'attributes'>>();
  const [contactData, setContactData] = useState<FooterContactType>();
  const [footerSocialData, setSocialData] =
    useState<Pick<FooterSocialType['data'], 'attributes'>>();

  useEffect(() => {
    // Header
    fetchMenuData(setMenuData);
    fetchBandData(setBandData);

    // Footer
    fetchFooterBannerData(setFooterBannerData);
    fetchFooterMenuData(setFooterMenuData);
    fetchContactData(setContactData);
    fetchSocialData(setSocialData);
  }, []);

  return (
    <>
      <BandSlider data={bandData} />
      <div className="bg-gray-20 mb-6 sticky top-0 z-[49] select-none">
        <div className="container">
          <div
            className={clsx(
              'grid',
              'header-m-template-cols',
              'header-grid-template-areas'
            )}
          >
            <div className="header-grid-area-main-l flex-center lg:hidden">
              <div className="w-12 h-12 flex-center">
                <MobileHamburgerButton />
              </div>
            </div>
            <div className="header-grid-area-main-m flex-center py-3 lg:py-8">
              <Link href={ROUTES.HOME} passHref={true} aria-label="Homepage">
                <HeaderLogo />
              </Link>
            </div>
            <div className="header-grid-area-main-r flex-center lg:hidden">
              <div className="w-12 h-12 flex-center">
                <MobileSearchButton />
              </div>
            </div>
            <div className="header-grid-area-nav">
              <Navbar menu={menuData} />
            </div>
          </div>
          <MobileMenu menu={menuData} />
        </div>
      </div>
      <div className="container">
        <ErrorCode code="500" />
      </div>
      <div className="bg-gray-20 lg:pt-20 mt-6 lg:border-t lg:border-gray select-none">
        <div className="container pb-[30px] md:pb-24">
          <FooterBannerContent data={footerBannerData} />

          <div className="px-4 pb-0 mx-auto md:flex md:flex-wrap md:px-0 lg:pb-16">
            <FooterMenuContent
              data={footerMenuData}
              contactComponent={<FooterContactContent data={contactData} />}
              socialComponent={<FooterSocialContent data={footerSocialData} />}
            />
          </div>

          <div className="grid md:grid-cols-2 gap-8 px-4 md:px-0">
            <div className="border-t border-gray">
              <div className="mt-6 mb-[40px] text-[9px] leading-4 text-black md:mb-[30px]">
                <span>{t('common.footer.payment_methods')}</span>
              </div>
              <div className="flex gap-4 md:gap-6 h-8">
                <Image
                  width={40}
                  height={32}
                  src="/payment/visa.svg"
                  alt="Visa"
                />
                <Image
                  width={50}
                  height={32}
                  src="/payment/applepay.svg"
                  alt="Apple Pay"
                />
                <Image
                  width={40}
                  height={32}
                  src="/payment/master.svg"
                  alt="Mastercard"
                />
              </div>
            </div>
            <div className="flex border-t border-gray">
              <span className="mt-6 text-[11px] leading-5 text-black">
                © Forest Essentials
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
