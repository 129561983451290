'use client';

import { SwiperReact, SwiperSlide } from 'components';
import { Autoplay } from 'swiper';

type BandSliderItem = {
  value: {
    link?: string;
    text_color?: string;
    color?: string;
    text: string;
  };
};

export type BandSliderType = {
  data: {
    attributes: {
      header_band_text: BandSliderItem[];
    };
  };
};

const BandSlider = ({ data }: BandSliderType) => {
  const hex_code = (hex: string) => {
    return hex?.includes('#') ? true : false;
  };

  const dynamicBackgroundClass = (hex: string) => {
    return hex_code(hex)
      ? null
      : `bg-${hex}` || hex === undefined
      ? 'bg-black'
      : `bg-${hex}`;
  };

  const dynamicTextColorClass = (hex: string) => {
    return hex_code(hex)
      ? null
      : `text-${hex}` || hex === undefined
      ? 'text-white'
      : `text-${hex}`;
  };

  if (!data) return null;

  return (
    <SwiperReact
      modules={[Autoplay]}
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      }}
    >
      {data?.attributes?.header_band_text?.map((item, index) => (
        <SwiperSlide key={index}>
          <div
            className={`px-4 lg:px-0 flex items-center justify-center ${
              !hex_code(item.value.color)
                ? dynamicBackgroundClass(item.value.color)
                : ''
            }`}
            style={{
              backgroundColor: hex_code(item.value.color)
                ? item.value.color
                : ''
            }}
          >
            {(() => {
              if (item.value.link) {
                return (
                  <a
                    href={item.value.link}
                    className={`font-sans uppercase text-xs pt-3 pb-2 ${
                      !hex_code(item.value.text_color)
                        ? dynamicTextColorClass(item.value.text_color)
                        : ''
                    }`}
                    style={{
                      color: hex_code(item.value.text_color)
                        ? item.value.text_color
                        : ''
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.value.text
                      }}
                    />
                  </a>
                );
              } else {
                return (
                  <span
                    className={`font-sans uppercase text-xs pt-3 pb-2 cursor-default ${
                      !hex_code(item.value.text_color)
                        ? dynamicTextColorClass(item.value.text_color)
                        : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: item.value.text
                    }}
                  />
                );
              }
            })()}
          </div>
        </SwiperSlide>
      ))}
    </SwiperReact>
  );
};

export default BandSlider;
