import { Icon, Link, Trans } from '@theme/components';
import { FooterSocialType } from '@theme/widgets/footer-social';
import clsx from 'clsx';

export const FooterSocialContent = ({ data }: FooterSocialType) => {
  const iconHoverClasses = {
    facebook: 'hover:text-[#3B5998]',
    instagram: 'hover:text-[#DD2A7B]',
    youtube: 'hover:text-[#FF0000]'
  };

  return (
    <div className="block pt-6 pb-12 border-gray md:pt-7 md:pb-4 md:border-t md:justify-center">
      <div className="text-xs text-black-400 leading-3 uppercase mb-6 md:mb-3">
        <Trans i18nKey="common.footer.follow" />
      </div>

      <div className="flex">
        {data?.attributes?.social_items?.map((item, i) => (
          <Link
            key={i}
            href={item?.value?.redirect_url}
            className="mr-6 md:mr-3 md:last:mr-0 rtl:md:last:mr-3 rtl:md:first:mr-0"
            target={
              item?.value?.is_target_blank === 'true' ? '_blank' : '_self'
            }
          >
            <Icon
              className={clsx(
                'text-gray-650 transition-colors duration-300',
                `${iconHoverClasses[item?.value?.site_name] || ''}`
              )}
              size={30}
              name={item?.value?.icon_class?.replace('pz-icon-', '')}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
