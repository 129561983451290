import { Image } from '@akinon/next/components/image';

export const FooterBannerContent = ({ data }) => {
  return (
    <div className="grid grid-cols-1 px-4 md:gap-x-8 md:grid-cols-2 md:px-0 lg:grid-cols-4">
      {data?.attributes?.footer_banner_item?.map((item, i) => (
        <div key={i}>
          <div className="mb-[30px] mt-6 border-b border-gray md:border-b-0 lg:mb-0 lg:mt-0"></div>

          <div className="flex justify-center mb-6 md:justify-start md:rtl:justify-start md:mb-8 lg:mb-10">
            <Image
              src={item.kwargs.value.image.url}
              alt={item.kwargs.value.image.url}
              width={200}
              height={200}
              quality={100}
              className="w-[100px] h-[100px]"
            />
          </div>

          <div className="flex flex-col gap-y-[22px] md:gap-y-9">
            <div className="font-serif text-black-400 text-xl text-center md:text-left rtl:md:text-right lg:text-[26px] lg:leading-[29.7px]">
              {item.value.title}
            </div>
            <div className="font-sans text-black-400 text-sm text-center md:text-left rtl:md:text-right lg:leading-[22px]">
              {item.value.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
